$(document).ready(function () {
  function form_builder_email_format(value) {
	  var pattern = /^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,3})$/;

	  return pattern.test(value);
  }

  var form = $('form.form--newsletter.email_sms_signup');

  $('.form--errors--email_sms_signup').hide();

  form.bind('submit', function (e) {
	  var error = $(this).find('.form--errors--email_sms_signup'),
	      email_address = $(this).find('.form--email_sms_signup--field--EMAIL_ADDRESS').val();

	  if (!form_builder_email_format(email_address)) {
		  error.show();

		  return false;
	  }

	  error.hide();

	  return true;
  });
});
